export const TABLE_LIST = [
  {
    id: 1,
    ingredient: 'Ingredient 01',
    weight_plan: 33,
    weight_fact: 0,
    waste_percent: 33,
    waste_kg: 0,
    start_loading: '11:15',
    time_loading: 27,
    operator: 'Vasiliy'
  },
  {
    id: 2,
    ingredient: 'Ingredient 02',
    weight_plan: 21,
    weight_fact: 0,
    waste_percent: 21,
    waste_kg: 0,
    start_loading: '11:32',
    time_loading: 1,
    operator: 'Vasiliy'
  },
  {
    id: 3,
    ingredient: 'Ingredient 02',
    weight_plan: 19,
    weight_fact: 0,
    waste_percent: 19,
    waste_kg: 0,
    start_loading: '11:33',
    time_loading: 8,
    operator: 'Vasiliy'
  },
  {
    id: 4,
    ingredient: 'Ingredient 04',
    weight_plan: 42,
    weight_fact: 0,
    waste_percent: 42,
    waste_kg: 0,
    start_loading: '11:42',
    time_loading: 10,
    operator: 'Vasiliy'
  }
]
