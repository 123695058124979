<template lang="pug">
  .reports-load-table
    .reports-load-table__row.reports-load-table__row--header
      .reports-load-table__column {{ 'pages.reports.load_time' | translate }}

    .reports-load-table__body
      reports-load-table-row(
        v-for="item in items"
        :key="item.id"
        :item="item")

</template>

<script>
  import ReportsLoadTableRow from './ReportsLoadTableRow'

  export default {
    name: 'ReportsLoadTable',

    components: {
      ReportsLoadTableRow
    },

    props: {
      items: Array
    }
  }
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables';

  .reports-load-table {
    &__row {
      position: relative;
      padding: 1rem 1.5rem;
      display: flex;
      align-items: center;

      &--header {
        padding: 1.5rem;
        color: $color-gray;
      }

      &--body {
        border-top: .1rem solid $color-gray-light-2;

        .reports-load-table {
          &__column {
            padding-right: 1rem;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // white-space: nowrap;
            // max-width: 20rem;

            &:nth-child(1) {
              flex: 1 1 15%;
            }

            &:nth-child(2) {
              flex: 1 1 18%;
            }

            &:nth-child(3) {
              flex: 1 1 25%;
            }

            &:nth-child(4) {
              flex: 1 1 15%;
            }

            &:nth-child(5) {
              flex: 1 1 10%;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
</style>
