<template lang="pug">
  v-card
    .reports-table__row.reports-table__row--body
      .reports-table__column {{ item.date | date }}
      .reports-table__column(
        :style="{color: statuses[item.status].color}")
        | {{ statuses[item.status].name | translate }}
      .reports-table__column {{ item.recipe_name }}
      .reports-table__column {{ item.plan_weight }} / {{ item.fact_weight }}
      .reports-table__column {{ item.load_time | sectomin }} {{ 'units.m' | translate }}
      .chevron-box.chevron-box--position-right(
        @click="toggled = !toggled")
        v-chevron(
          v-if="item.status !== 'empty'"
          :value="toggled")

    template(v-if="toggled")
      v-divider(secondary)
      reports-load-table(:items="item.batches")

</template>

<script>
import { PLANNING_STATUSES } from '@/util/consts.js'

import ReportsLoadTable from './ReportsLoadTable'

export default {
  name: 'ReportsTableRow',

  components: {
    ReportsLoadTable
  },

  props: {
    item: Object
  },

  data: () => ({
    statuses: PLANNING_STATUSES,
    toggled: false
  })
}
</script>

<style lang="scss" scoped>
</style>
