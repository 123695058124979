<template lang="pug">
  .reports-load-table-row
    .reports-load-table__row.reports-load-table__row--body
      .reports-load-table__column {{ item.time || '¯\\_(ツ)_/¯' }}
      .reports-load-table__column(
        :style="{color: statuses[item.status].color}")
        | {{ statuses[item.status].name | translate }}
      .reports-load-table__column {{ item.recipe_name }}
      .reports-load-table__column {{ item.plan_weight }} / {{ item.fact_weight }}
      .reports-load-table__column {{ item.load_time | sectomin }} {{ 'units.m' | translate }}
      .chevron-box.chevron-box--position-right(
        @click="toggled = !toggled")
        v-chevron(
          :value="toggled")

    template(v-if="toggled")
      table.table.table--gray.table--cell-overflow-hidden
        thead.table__header.table__header--divider-top
          tr.table__row.table__row--header.table__row--small-text
            th.table__cell {{ 'base.ingredients' | translate }}
            th.table__cell {{ 'base.weight' | translate }} [{{ 'units.kg' | translate }}] #[br] {{ 'base.plan' | translate }} / {{ 'base.fact' | translate }}
            th.table__cell {{ 'pages.reports.deviation' | translate }} [% / {{ 'units.kg' | translate }}]
            th.table__cell(v-html="$t('pages.reports.load_start_br')")
            th.table__cell(v-html="$t('pages.reports.load_time_br')")
            th.table__cell {{ 'base.operator' | translate }}
        tbody.table__body.table__body--divider-bottom
          tr.table__row.table__row--body(
            v-for="item in item.ingredients"
            :key="item.id")
            td.table__cell {{ item.ingredient_name }}
            td.table__cell {{ item.plan_weight || 0 | rounding }} / {{ item.fact_weight || 0 | rounding }}
            td.table__cell {{ item.rate_deviation || 0 | rounding }} / {{ item.weight_deviation || 0 | rounding }}
            td.table__cell {{ item.time }}
            td.table__cell {{ item.loaded_time }}
            td.table__cell {{ item.user }}

</template>

<script>
  import { PLANNING_STATUSES } from '@/util/consts.js'
  import { TABLE_LIST } from '@/assets/data/reports.js'

  export default {
    name: 'ReportsLoadTableRow',

    props: {
      item: Object
    },

    data: () => ({
      statuses: PLANNING_STATUSES,
      list: TABLE_LIST,
      toggled: false
    })
  }
</script>

<style lang="scss" scoped>
</style>
